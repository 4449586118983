<template>
  <Editor :init="init" @onInit="onReady" v-model="html" v-loading="loading" />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TinyMce",
  props: {
    height: {
      type: Number,
      default() {
        return 800;
      },
    },
    content: {
      type: String,
      default: () => null,
    },
  },
  data() {
    const init = {
      // readonly:true,
      statusbar: false,
      // inline: true,
      plugins: "autoresize",
      min_height: this.height || 0,
      autoresize_overflow_padding: 1,
      toolbar: "",
      menubar: "",
      // content_css: "tinymce.css",
      convert_urls: false,
    //  "../../assets/tinymce.css
    };
    return {
      init,
      editor: null,
      html: null,
      loading: false,
    };
  },
  components: { Editor },
  watch: {
    content: {
      immediate: true,
      handler(val) {
        this.loading = true;
        this.html = val;
      },
    },
  },
  created() {},
  methods: {
    onReady(e) {
      console.log(e)
      this.editor = e.target;
      this.editor.mode.set("readonly");
    },
  },
};
</script>

<style lang="less" scoped></style>
