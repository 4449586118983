<template>
  <div class="vip">
    <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs_a">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/productsServices/vipZone' }"
          >会员权益俱乐部</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '' }">{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main" v-if="main">
      <main>
        <Editor :readonly="true" :content="main" :height="0"></Editor>
      </main>
    </div>
    <div class="empty" v-else>
      <!--      <div class="text">-->
      <!--        <p>暂无内容</p>-->
      <!--      </div>-->
      <empty></empty>
    </div>
  </div>
</template>

<script>
import empty from "@/components/empty";
import { proVipZone } from "@/api/vipZone";
import Editor from "@/components/TinyMce";
export default {
  name: "vipText",
  data() {
    return {
      main: null,
      title: "",
    };
  },
  created() {
    this.vip();
  },
  mounted() {},
  components: { Editor, empty },
  methods: {
    async vip() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await proVipZone({
          timeStamp,
          sign,
          labelType: this.$route.params.id,
          modelType: "pre",
        });
        console.log("产品详情", res.data);
        if (res.data.code == 200) {
          console.log(res.data.data);
          this.main = res.data.data.labelContent;
          this.title = res.data.data.labelName;
        }
        // if (
        //     res.data.code == 401 ||
        //     res.data.code == 404 ||
        //     res.data.code == 500
        // ) {
        //   this.$router.replace("/failure");
        // }
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="less">
.vip {
  .main {
    width: 100%;

    > .time {
      width: 1034px;
      text-align: right;
      margin: 5rem auto 2rem;
      font-size: 2.2rem;
    }

    > main {
      width: 1034px;
      // height: 700px;
      // border: 1px solid;
      margin: 2rem auto 6rem;
    }

    //> .text {
    //  width: 80%;
    //  margin: auto;
    //  > p {
    //    font-size: 5rem;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    height: 25vw;
    //    color: #999;
    //  }
    //}
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
::v-deep.el-breadcrumb {
  padding: 10px 0;
  width: 80vw;
  margin: auto;
  font-size: 1.5rem;

  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}

::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}

::v-deep.el-loading-mask {
  z-index: 1;
}
</style>