import http from "@/utils/request";


//会员权益
export function vipZone(data) {
    return http({
        url: "/azCustomer/byType",
        method: "post",
        data,
    });
}
//会员权益草稿箱
export function proVipZone(data) {
    return http({
        url: '/azCustomer/getProByType',
        params: data,
        method: "get",
    });
}